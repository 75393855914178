var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "choose-words-by-pic-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c("div", { staticClass: "choose-hanzi-box" }, [
            _c("div", { staticClass: "answer-area" }, [
              _c("div", { staticClass: "answer-img" }, [
                _c("img", {
                  attrs: {
                    src: _vm.chooseHanziList[
                      _vm.currentIndex >= _vm.answerIdList.length
                        ? _vm.answerIdList.length - 1
                        : _vm.currentIndex
                    ].bgImg,
                    alt: "",
                  },
                }),
              ]),
              !_vm.isShowAllWords
                ? _c(
                    "div",
                    { staticClass: "seasaw-area" },
                    [
                      _c("img", {
                        staticClass: "origin-img",
                        attrs: { src: _vm.gameInfo.seasawBottomImg, alt: "" },
                      }),
                      !_vm.firstImgId
                        ? _c("img", {
                            staticClass: "base-img",
                            attrs: { src: _vm.gameInfo.baseImg, alt: "" },
                          })
                        : _vm._e(),
                      _vm._l(_vm.stepsImgList, function (item, index) {
                        return [
                          _vm.spliceId === item.id
                            ? _c("img", {
                                key: index,
                                staticClass: "choose-item",
                                class: {
                                  trunLeft: _vm.isTurnLeft,
                                  trunMiddle: _vm.isTurnMiddle,
                                  trunRight: _vm.isTurnRight,
                                },
                                attrs: { src: item.bgImg, alt: "" },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isShowAllWords
                ? _c("div", { staticClass: "answer-right-hanzi-area" }, [
                    _c("div", { staticClass: "right-hanzi-box" }, [
                      _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(
                          _vm._s(
                            _vm.chooseHanziList[_vm.currentIndex].wordsPinyin
                          )
                        ),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(
                          _vm._s(
                            _vm.chooseHanziList[_vm.currentIndex].wordsHanzi
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "space-area" }),
            ]),
            _c(
              "div",
              { staticClass: "choose-hanzi-area" },
              _vm._l(
                _vm.chooseHanziList[
                  _vm.currentIndex >= _vm.answerIdList.length
                    ? _vm.answerIdList.length - 1
                    : _vm.currentIndex
                ].hanziChooseList,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index + "choose",
                      staticClass: "hanzi-item",
                      class: {
                        dispear: _vm.choosedHanziIdList.indexOf(item.id) > -1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleChooseHanzi(item.id)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                        _vm._v(_vm._s(item.pinyin)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  )
                }
              ),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }