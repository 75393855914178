<template>
  <div class="choose-words-by-pic-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      />
      <!-- page  chooose hanzi -->
      <div class="choose-hanzi-box">
        <div class="answer-area">
          <div class="answer-img">
            <img
              :src="
                chooseHanziList[
                  currentIndex >= answerIdList.length
                    ? answerIdList.length - 1
                    : currentIndex
                ].bgImg
              "
              alt=""
            />
          </div>
          <div class="seasaw-area" v-if="!isShowAllWords">
            <img :src="gameInfo.seasawBottomImg" alt="" class="origin-img" />
            <img
              :src="gameInfo.baseImg"
              alt=""
              class="base-img"
              v-if="!firstImgId"
            />
            <template v-for="(item, index) in stepsImgList">
              <img
                class="choose-item"
                :src="item.bgImg"
                alt=""
                :key="index"
                v-if="spliceId === item.id"
                :class="{
                  trunLeft: isTurnLeft,
                  trunMiddle: isTurnMiddle,
                  trunRight: isTurnRight,
                }"
              />
            </template>
          </div>

          <div class="answer-right-hanzi-area" v-if="isShowAllWords">
            <div class="right-hanzi-box">
              <span class="pinyin font-pinyin-medium">{{
                chooseHanziList[currentIndex].wordsPinyin
              }}</span>
              <span class="font-hanzi-medium">{{
                chooseHanziList[currentIndex].wordsHanzi
              }}</span>
            </div>
          </div>
          <div class="space-area"></div>
        </div>

        <!-- 无提示部分 -->

        <div class="choose-hanzi-area">
          <div
            class="hanzi-item"
            v-for="(item, index) in chooseHanziList[
              currentIndex >= answerIdList.length
                ? answerIdList.length - 1
                : currentIndex
            ].hanziChooseList"
            :key="index + 'choose'"
            :class="{ dispear: choosedHanziIdList.indexOf(item.id) > -1 }"
            @click="handleChooseHanzi(item.id)"
          >
            <span class="pinyin font-pinyin-medium">{{ item.pinyin }}</span>
            <span class="font-hanzi-medium">{{ item.hanzi }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";
export default {
  name: "SeesawGame",
  props: {
    chooseHanziList: {
      type: Array,
      require: true,
    },
    optionsList: {
      type: Array,
      require: true,
    },
    gameInfo: {
      type: Object,
      require: true,
    },
    starNum: {
      type: Number,
      require: true,
    },
    stepsImgList: {
      type: Array,
      require: true,
    },
    answerIdList: {
      type: Array,
      require: true,
    },
  },

  components: {
    SceneSwitchThumbnails,
    PageButton,
  },

  data() {
    return {
      isShowAllWords: false,
      choosedHanziIdList: [],
      hanziSocketInfo: {},
      clickMenuSocketInfo: {},
      currentIndex: 0,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      order: 1,
      firstImgId: 0,
      spliceId: 0,
      isTurnLeft: false,
      isTurnMiddle: false,
      isTurnRight: false,
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },
  mounted() {
    this.$bus.$on("clickToChooseWordsSeesaw", ({ id }) => {
      this.handleChooseHanzi(id, true);
    });
    this.$bus.$on("seesawGameClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickToChooseWordsSeesaw");
    this.$bus.$off("seesawGameClickMenu");
  },
  watch: {
    hanziSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20050,
          data: { value },
          text: "SeesawGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005001,
          data: { value },
          text: "SeesawGame menu click socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index);
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.initGame();
    },
    initGame() {
      this.order = 1;
      this.firstImgId = 0;
      this.spliceId = 0;
      this.choosedHanziIdList = [];
      this.isShowAllWords = false;
      this.isTurnLeft = false;
      this.isTurnMiddle = false;
      this.isTurnRight = false;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleChooseHanzi(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.hanziSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      console.log(id);
      if (this.order === 1) {
        setTimeout(() => {
          this.isTurnLeft = true;
        }, 500);
        this.choosedHanziIdList.push(id);
        this.firstImgId = id;
        this.spliceId = id;
        console.log(this.spliceId);
        // 检测第一次
        this.order = 2;
      } else {
        this.choosedHanziIdList.push(id);
        this.spliceId = parseInt(this.firstImgId + "" + id);
        console.log(this.spliceId);
        if (this.spliceId === this.answerIdList[this.currentIndex]) {
          setTimeout(() => {
            this.isTurnMiddle = true;
          }, 500);
          setTimeout(() => {
            this.isShowAllWords = true;
            playCorrectSound(true, false);
          }, 3000);
          setTimeout(() => {
            if (this.currentIndex === this.chooseHanziList.length) {
              this.currentIndex === this.chooseHanziList.length - 1;
              this.isLastStep = true;
              playCorrectSound();
              startConfetti();
            }
          }, 4500);
        } else {
          setTimeout(() => {
            this.isTurnRight = true;
          }, 500);
          setTimeout(() => {
            playCorrectSound(false);
            this.initGame();
          }, 3000);
        }
        // 检测第二次
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-words-by-pic-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f7f4e0;
    border-radius: 58px;
    display: flex;
    flex-direction: column;
  }
  .choose-hanzi-box {
    width: 100%;
    height: 100%;
    .answer-area {
      height: 70%;
      display: flex;
      align-items: center;
      .answer-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;
        img {
          width: 90%;
          //   border: 10px solid #fff;
          //   box-sizing: border-box;
          // transform: rotate(-10deg);
        }
      }
      .seasaw-area {
        flex: 4;
        height: 60%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        position: relative;
        .origin-img {
          position: absolute;
          height: 55%;
          z-index: 10;
        }
        .base-img {
          width: 85%;
          margin-bottom: 30px;
          z-index: 1;
          transform: rotate(0deg);
        }
        .choose-item {
          width: 85%;
          margin-bottom: 150px;
          z-index: 1;
          transform: rotate(0deg);
          transform-origin: 50% 85%;
        }
        .trunLeft {
          transform: rotate(-10deg);
          transition: transform 1.5s linear;
        }
        .trunRight {
          transform: rotate(10deg);
          transition: all 1.5s linear;
        }
        .trunMiddle {
          transform: rotate(0deg);
          transition: all 1.5s linear;
        }
      }
      .answer-right-hanzi-area {
        height: 100%;
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-hanzi-box {
          border-radius: 30px;
          background: #224e96;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px 40px;
          span {
            display: inline-block;
            color: #fff;
          }
        }
      }
      .space-area {
        flex: 1;
      }
    }
    .choose-hanzi-area {
      margin: 0 auto;
      height: 35%;
      width: 65%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../../../../assets/img/16-GAMES/G18-maze2/control.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .hanzi-item {
        box-sizing: border-box;
        width: 18%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-right: 20px;
        margin: 0 10px;

        background-color: #f4d737;
        color: #224e96;

        border-right: 12px solid #dec32c;
        border-top: 12px solid #dec32c;

        border-radius: 30px;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
      .dispear {
        opacity: 0;
      }
      // .hanzi-item:hover {
      //   background-color: #912016;
      //   border-bottom: 12px solid #d87065;
      //   border-top: 12px solid #a53d32;
      // }
    }
  }
}
</style>
